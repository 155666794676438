<template>
  <base-page>
    <Filters v-if="filterModel" :model="filterModel"
      color="comite" slot="left-column" />
    <router-view :key="$route.fullPath" />
  </base-page>
</template>

<script>
import { Filters } from '../../components/filters'

export default {
  name: 'ComitePage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Comité | AGORA`
    }
  },

  computed: {
    filterModel () {
      const routeName = this.$route.name
      if (routeName === 'instances-list') { return 'instances' }
      if (routeName === 'sujets-list') { return 'sujets' }
      if (routeName === 'sujets-a-planifier-list') { return 'sujets' }
      return null
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])

    this.$store.commit('pages/setTabs', [
      { name: 'instances', label: 'Instances', to: { name: 'instances-list' } },
      { name: 'comptes-rendus', label: 'Comptes-rendus', to: { name: 'compte-rendus-list' } },
      { name: 'sujets', label: 'Sujets', to: { name: 'sujets-list' } },
      { name: 'sujets-a-planifier', label: 'Sujets à planifier', to: { name: 'sujets-a-planifier-list' } }
    ])
  }
}
</script>
